import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/style.scss'
import "./assets/css/materialdesignicons.min.css";

import { registerScrollSpy } from "vue3-scroll-spy";
import ScrollSpy from 'vue3-scroll-spy';
import VueScrollTo from 'vue-scrollto';
import VueParticles from 'vue-particles'
import Vue3Youtube from 'vue3-youtube';
import VueFeather from 'vue-feather';
import VueSocialChat from "vue-social-chat";
import CustomScrollbar from 'custom-vue-scrollbar';
import StarRating from "vue-star-rating";
import { createGtm } from '@gtm-support/vue-gtm';
import 'custom-vue-scrollbar/dist/style.css';


// Create the Vue application
const app = createApp(App);

// Use the plugins
app.use(router);
app.use(
  createGtm({
    id: 'GTM-KZX4RJD9', 
      queryParams: {
        gtm_preview: 'env-4',
        gtm_cookies_win: 'x',
      },
      defer: false,
      compatibility: false,
      enabled: true,


    vueRouter: router,
    trackViewEventProperty: 'page-load'
  }),
);
app.use(VueParticles);
app.use(BootstrapVueNext);
app.use(Vue3Youtube);
app.use(registerScrollSpy);
app.use(ScrollSpy);
app.use(VueScrollTo);
app.use(VueSocialChat);
app.component(VueFeather.name, VueFeather);
app.component(CustomScrollbar.name, CustomScrollbar);
app.component('star-rating', StarRating.default)
// Mount the app to the DOM
app.mount('#app');
