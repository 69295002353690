<template>
  <div id="app">
    <!-- Loader -->
    <div id="preloader" v-if="loading">
      <div id="status">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
    <custom-scrollbar
      class="customCls"
      :style="{ width: '100%', height: '100vh' }"
    >
      <router-view />
    </custom-scrollbar>
  </div>
</template>

<script>
import "vue-social-chat/dist/style.css";
export default {
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  created() {
    setTimeout(() => (this.loading = false), 600);
  },
};
</script>

<style></style>
